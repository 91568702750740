<template>
  <span class="spinner">
    <nuxt-icon name="spinner/rolling" filled />
  </span>
</template>

<script lang="ts">
export default {
  name: 'Spinner',
}
</script>

<style scoped>
.spinner {
  line-height: 0;
}
</style>
